import * as React from 'react'

const QuestionAnswer = () => {
    // Add new QA items to the array as an array of two strings.
    const qaArr = [
        ["Are you still teaching lessons during Covid-19?", "Yes, I'm still teaching shamisen lessons!"],
        ["I don't know anything about shamisen or Japanese music, can I still take a lesson?", "Absolutely, I teach lessons for all ability levels."],
        ["I don't speak any Japanese...", "No problem! I conduct all my lessons in English or Japanese at the students request."],
        ["Do I need to bring my own shamisen?", "You don't need your own shamisen and I can provide you with one to use during our lessons."],
        ["Can I borrow a shamisen to practise at home?", "Sorry, but I am not able to lend out my shamisen to students. However if you wish to purchase one I am happy to give you advice on how to buy a shamisen."],
        ["Do you teach private or group lessons?", "I usually teach individual lessons but group lessons are possible upon request. Group lessons work best when students are around the same musical ability."],
        ["How do I book a lesson?", "Please contact Makoto to book a lesson."],
        ["What should I do if I need to reschedule or cancel a lesson?", "Please get in contact and we can reschedule our lesson."],
    ]

    const returnArr = qaArr.map((qa, index) => (
        <div key={index}>
            <p className='qa-q'>
                Q. {qa[0]}
            </p>
            <p className='qa-a'>
                A. {qa[1]}
            </p>
        </div>
    ))

    return (
        <div className='qanda-content'>
            {returnArr}
        </div>
    )
}

export default QuestionAnswer