import * as React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import SectionBreak from '../components/sectionBreak'
import ContactDetails from '../components/contactDetails'
import QuestionAnswer from '../components/qa'
import '../css/index.css'

const IndexPage = () => {
  return (
    <Layout pageTitle='Home'
    // The keys here is a bit of a hack to stop React throwing errors. Elsewhere it's added via function) 
    subTitle={[<span key="0">Welcome to Learn Shamisen</span>, <span key="1">Lessons in Japanese Music</span>]} description='Learn how to play the shamisen in Tokyo. Book shamisen lessons in English or Japanese with Makoto Nishimura.'>
      <div className='index-page'>
        <section className='section-two-columns'>
          <div>
            <h2 className='section-header page-left'>
              <span>Getting Started</span>
            </h2>
            <div className='section-content'>
              <p>
                If you live in the Tokyo area and want to learn how to play shamisen, Makoto Nishimura is currently accepting new students. This is a unique opportunity to get hands on experience of traditional Japanese music. Lessons are held in English or Japanese so there's no need to worry if you don't speak Japanese. If you want to get straight to it and book a shamisen lesson or just want to ask Makoto a question then please feel free to send a message.
              </p>
              <p className='button-link'>
                <Link to='/contact/'>
                  Contact Makoto
                </Link>
              </p>
              <p>
                If you're completely new to Japanese music and want to learn more about shamisen why not start here?
              </p>
              <p className='button-link'>
                <Link to='/whatIsShamisen/shamisenIntroduction/'>
                  What is shamisen?
                </Link>
              </p>
            </div>
          </div>
          <StaticImage
            className='section-image'
            alt='A row of differently colored shamisen on cusions.'
            src='../images/home/shamisen_in_a_line.webp'
            layout='constrained'
          />
        </section>

        <SectionBreak sectionBreakText='Who will teach me?' />

        <section className='section-two-columns section-reverse'>
          <div>
            <h2 className='section-header page-right'>
              <span>Your Teacher: Makoto Nishimura</span>
            </h2>
            <div className='section-content'>
              <p>
                A graduate of the Tokyo University of Fine Arts, Makoto has been playing and teaching for more than 40 years. She teaches nagauta, the shamisen style heard in Kabuki and as an accompaniment for traditional Japanese dance.
              </p>
              <p>
                Makoto Nishimura's goals for her teaching are twofold: to spread shamisen music in the world, and to enjoy playing music. The former is difficult, as these days the shamisen is only rarely seen or heard in Japan, let alone abroad. Nishimura plays nagauta shamisen music, a style still heard in the Kabuki theatre today.
              </p>
              <p className='button-link'>
                <Link to='/aboutMakoto/'>
                  Makoto's Profile
                </Link>
              </p>
            </div>
          </div>
          <StaticImage
            className='section-image'
            alt='Makoto Nishimura plays the shamisen in a purple kimono'
            src='../images/home/makoto_nishimura2.webp'
          />
        </section>

        <SectionBreak sectionBreakText='What are lessons like?'></SectionBreak>

        <section className='section-two-columns'>
          <div>
            <h2 className='section-header page-left'>
              <span>Shamisen Lessons</span>
            </h2>
            <div className='section-content'>
              <p>
                Makoto specializes in teaching shamisen to foreign students with lessons being offered in English or Japanese. For interested students a free introductory lesson is also offered. If you want to learn to play shamisen with a friend small group lessons are also possible. Makoto teaches primarily because she enjoys introducing people to traditional music, not in order to make money.
              </p>
              <p>
                Because of this, her lessons are very reasonably-priced. For her regular students, a few times each year there are opportunities to perform in concerts together with professional musicians. For more information on what you can expect from a shamisen lesson follow the link below.
              </p>
              <p className='button-link'>
                <Link to='/lessons/'>
                  Shamisen lessons
                </Link>
              </p>
            </div>
          </div>
          <StaticImage
            className='section-image'
            alt='Makoto Nishimura teaching a shamisen class'
            src='../images/home/lesson.webp'
          />
        </section>

        <SectionBreak sectionBreakText='I want to book a lesson!' />

        <section className='section-two-columns section-reverse'>
          <div>
            <h2 className='section-header page-right'>
              Contact &amp; Map
            </h2>
            <div className='section-content' id='contact-and-map'>
              <p>
                Want to know more about learning shamisen in Tokyo, ask a question about lessons, or make a booking?
              </p>
              <ContactDetails />
              <p className='button-link'>
                <Link to='/contact/'>
                  Contact Makoto
                </Link>
              </p>
            </div>
          </div>
          <div className='section-image'>
            <iframe title='google-map' className='gmap' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3243.200797616252!2d139.66413631545828!3d35.62277604078266!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018f4e9577e6da1%3A0xed8401a44caeab65!2s605%2C%205-ch%C5%8Dme-17-22%20Yakumo%2C%20Meguro%20City%2C%20Tokyo%20152-0023!5e0!3m2!1sen!2sjp!4v1652423597244!5m2!1sen!2sjp" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </section>

        <SectionBreak sectionBreakText='I still have some questions...' />

        <section className='qanda'>
          <h2 className='section-header'>
            Q&amp;A
          </h2>
          <QuestionAnswer />
        </section>
      </div>
    </Layout>
  )
}

export default IndexPage